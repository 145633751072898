import { Box, Button, Spinner, Text, Image } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { CartItemProps, NavbarOption, ProductData } from '../../interfaces/components';
import SplashDesktop from '../../assets/videos/SplashDesktopMinified.mp4';
import SplashMobile from '../../assets/videos/SplashMobileMinified.mp4';
import TopOverlay from '../../assets/images/TopOverlay.webp';
import React from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { useGlobalStore } from '../../store/global';
import ReactPlayer from 'react-player';
import useWindowDimensions from '../../utils/useWindowDimensions';
import CartDrawer from './Cart';
import WMProductDrawer from '../WMProductDrawer';
import useViewportHeight from '../../utils/useViewPortHeight';
import { dispatchViewCartToAnalytics } from '../../analytics';
import axios from 'axios';

interface OverlayProps {
  children: React.ReactNode;
}

function Overlay({ children }: OverlayProps) {
  useViewportHeight();
  const navigate = useNavigate();
  const location = useLocation();
  const [uiLoaded, setUiLoaded] = useState(false);
  const { width } = useWindowDimensions();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [activeView, setActiveView] = useState<string>('shops');
  const [productIdTrail, setProductIdTrail] = useState<string[]>([]);
  const {
    bannerClosed,
    setBannerClosed,
    productDrawerLoading,
    productDrawerData,
    setProductDrawerData,
    setProductDrawerLoading,
    productTrailStartedId,
    setProductIdTrailStartedId,
  } = useGlobalStore();
  const [cartActive, setCartActive] = useState<boolean>(false);
  const [cartItems, setCartItems] = useState<CartItemProps[]>([]);

  useEffect(() => {
    const cart = localStorage.getItem('app_wm_cart');
    if (cart !== null) {
      setCartItems(JSON.parse(cart) as CartItemProps[]);
    }
  }, []);

  useEffect(() => {
    if (!cartActive) return;
    dispatchViewCartToAnalytics({
      ecommerce: { value: 0, currency: 'USD', items: [] },
      product_source: 'direct',
    });
  }, [cartActive]);

  const updateParentCart = (cart: CartItemProps[]) => {
    localStorage.setItem('app_wm_cart', JSON.stringify(cart));
    setCartItems(cart);
  };

  const navigateMap = {
    sojelly: '/viewer/sojelly/plaza',
    yallternative: '/viewer/yallternative/plaza',
    chromatic: '/viewer/chromatic/plaza',
    royal: '/viewer/royal/room',
    sanrio: '/viewer/sanrio/room',
    serene: '/viewer/serene/retreat',
    y2k: '/viewer/y2k/room',
    arcade: '/viewer/arcade/room',
    exitStore: '/feed',
    enterShops: '/feed',
    enterDiscover: '/discover',
  };

  useEffect(() => {
    const messageHandler = (event: MessageEvent) => {
      if (event.data.type === 'nextShop' && navigateMap[event.data.payload as keyof typeof navigateMap]) {
        navigate(navigateMap[event.data.payload as keyof typeof navigateMap], {
          state: { playVideoFromButton: false },
        });
      } else if (event.data.type === 'lastShop' && navigateMap[event.data.payload as keyof typeof navigateMap]) {
        navigate(navigateMap[event.data.payload as keyof typeof navigateMap], {
          state: { playVideoFromButton: false },
        });
      } else if (event.data.type == 'updateCart') {
        updateParentCart(event.data.payload);
      } else if (navigateMap[event.data as keyof typeof navigateMap]) {
        navigate(navigateMap[event.data as keyof typeof navigateMap]);
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  useEffect(() => {
    const activeSidebarOption = navbarOptions.find(
      (sidebarOption: NavbarOption) => sidebarOption.href === location.pathname,
    );

    activeSidebarOption ? setActiveView(activeSidebarOption.key) : setActiveView('');
    window?.dataLayer?.push({
      environment: 'feed',
      store: activeSidebarOption ? activeSidebarOption.key : '',
    });
  }, [location.pathname]);

  const navbarOptions: NavbarOption[] = [
    {
      href: '/feed',
      key: 'shops',
      text: 'Shops',
    },
    {
      href: '/discover',
      key: 'discover',
      text: 'Discover',
    },
  ];

  const openProductModal = async (
    productVariantId: string,
    scene: string,
    fromRecommendation = false,
    addToTrail = false,
    removeFromTrail = false,
  ) => {
    let pId = productVariantId;

    console.log('productVariantId', productVariantId);
    console.log('scene', scene);
    console.log('fromRecommendation', fromRecommendation);
    console.log('addToTrail', addToTrail);
    console.log('removeFromTrail', removeFromTrail);

    if (removeFromTrail) {
      if (productIdTrail.length === 1) {
        pId = productTrailStartedId;
        setProductIdTrail([]);
        setProductIdTrailStartedId('');
      } else {
        pId = productIdTrail[1];
        setProductIdTrail((prevArray) => prevArray.slice(1));
      }
    } else if (addToTrail) {
      setProductIdTrail([productVariantId, ...productIdTrail]);
    } else {
      setProductIdTrailStartedId(productVariantId);
    }

    setProductDrawerLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/product/variant/${pId}`)
      .then((response) => {
        const product = response.data.data as ProductData;
        if (product.variants.some((variant) => variant.in_stock)) {
          setProductDrawerData({ productId: productVariantId, data: product, active: true, fromRecommendation });
          setProductDrawerLoading(false);
        } else {
          setProductDrawerLoading(false);
          setProductIdTrail([]);
          setProductIdTrailStartedId('');
        }
      })
      .catch((err) => {
        console.log(err);
        setProductDrawerLoading(false);
        setProductIdTrail([]);
        setProductIdTrailStartedId('');
      });
  };

  return (
    <Box w="100%" height="100%" display="flex" flexDirection="column" position="relative">
      {/* {!location.pathname.includes('viewer') && ( */}
      {uiLoaded && !location.pathname.includes('viewer') && (
        <Image
          src={TopOverlay}
          w={['100%']}
          height={['160px', '170px', '200px', '230px', '280px']}
          objectFit="cover"
          objectPosition="center"
          position="fixed"
          zIndex="85"
          alt="top gradient overlay"
          loading="eager"
        />
      )}

      {/* {!location.pathname.includes('viewer') && ( */}
      {uiLoaded && !location.pathname.includes('viewer') && (
        <Navbar
          cartItems={cartItems}
          activeView={activeView}
          navbarOptions={navbarOptions}
          setCartActive={(state) => setCartActive(state)}
        />
      )}

      {uiLoaded && (
        <Box display="flex" flex="1" transition="0.2s all" height={['100%']} zIndex="90">
          <Box flex="1" width={['100%']} display="flex" flexDirection="column">
            {children}
          </Box>
        </Box>
      )}

      {uiLoaded && (
        <Box
          zIndex="200"
          opacity={!bannerClosed ? 1 : 0}
          visibility={!bannerClosed ? 'visible' : 'hidden'}
          transition="all 0.2s"
          position="fixed"
          bottom="0px"
          bg="white"
          width="100%"
          height={['70px']}
          display="flex"
          flexDirection={['column', 'column', 'row']}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          gap={[1, 1, 6]}
        >
          <Text color="rgba(97, 97, 97, 1)" fontSize={['12px', '12px', '14px']} fontFamily="Bogle">
            © 2024 Walmart. All Rights Reserved.
          </Text>
          <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="center" gap={[4, 4, 6]}>
            <Button
              fontSize={['12px', '12px', '14px']}
              fontFamily="Bogle"
              fontWeight="100"
              textDecor="none"
              variant="link"
              color="rgba(97, 97, 97, 1)"
              onClick={() =>
                window.open(
                  'https://www.walmart.com/help/article/walmart-com-terms-of-use/3b75080af40340d6bbd596f116fae5a0',
                  '_blank',
                )
              }
            >
              Terms of Use
            </Button>

            <Button
              fontSize={['12px', '12px', '14px']}
              fontFamily="Bogle"
              fontWeight="100"
              textDecor="none"
              variant="link"
              color="rgba(97, 97, 97, 1)"
              onClick={() =>
                window.open('https://corporate.walmart.com/privacy-security/walmart-privacy-notice', '_blank')
              }
            >
              Privacy Notice
            </Button>
          </Box>

          <CloseIcon
            color="black"
            boxSize={[4]}
            right={['15px', '15px', '30px']}
            position="absolute"
            cursor="pointer"
            onClick={() => setBannerClosed(true)}
          />
        </Box>
      )}

      <Box
        opacity={showSplashScreen ? 1 : 0}
        visibility={showSplashScreen ? 'visible' : 'hidden'}
        transition={showSplashScreen ? 'unset' : 'visibility 0s linear 1s, opacity 1s'}
        className="loading"
        height="100%"
        width="100%"
        zIndex="500"
        position="fixed"
      >
        <ReactPlayer
          height="100%"
          width="100%"
          muted
          controls={false}
          playing={true}
          url={width < 769 ? SplashMobile : SplashDesktop}
          onEnded={() => {
            setUiLoaded(true);
            setShowSplashScreen(false);
          }}
          onError={() => {
            setUiLoaded(true);
            setShowSplashScreen(false);
          }}
          playsinline
        />
      </Box>

      <CartDrawer data={cartItems} active={cartActive} close={() => setCartActive(false)} setCartItems={setCartItems} />

      {productDrawerLoading && (
        <Spinner
          position="fixed"
          top="0px"
          left="0px"
          bottom="0px"
          right="0px"
          margin="auto"
          thickness="4px"
          speed="0.2s"
          color="white"
          size="xl"
          zIndex="9999"
        />
      )}
      <WMProductDrawer
        productId={productDrawerData.productId}
        productDrawerData={productDrawerData.data}
        productIdTrail={productIdTrail}
        active={productDrawerData.active}
        close={() => {
          setCartActive(false);
          setProductDrawerData({ ...productDrawerData, active: false });
        }}
        openCart={() => {
          setCartActive(true);
          setProductDrawerData({ ...productDrawerData, active: false });
        }}
        setCartItems={setCartItems}
        openProductModal={(variantId, fromRecommendation, addToTrail, removeFromTrail) =>
          openProductModal(variantId, '', fromRecommendation, addToTrail, removeFromTrail)
        }
        fromRecommendation={productDrawerData.fromRecommendation}
      />
    </Box>
  );
}

export default Overlay;
